<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">店家管理</li>
            <li class="breadcrumb-item active" aria-current="page">店家置頂排序管理</li>
        </ol>
        </nav>
        <hr class="w-100">
        <div class="mb-3"></div>
        <Form @submit="save()" @invalid-submit="on_invalid_submit">
            <div class="col-12 row mx-auto data-form mb-3">
                <div class="mb-3">
                    <label for="store_type" class="form-label">店家類型</label>
                    <div class="input-group">
                        <select id="store_type" class="form-select" v-model="store_type">
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in store_type_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                        <button type="button" class="btn btn-dark" v-on:click="search()" :disabled="store_type === ''">搜尋</button>
                    </div>
                </div>
            </div>
            <div class="col-12 row text-end mx-auto mb-3" v-if="role_list.store_top_ranking.role.split(',')[2] === '1'">
                <div>
                    <button type="button" class="btn m-2 btn-success" :disabled="search_flag === false" data-bs-toggle="modal" data-bs-target="#staticBackdropSearch" @click.prevent="search_store_data()">新增</button>
                </div>
            </div>
            <div class="col-12 row mx-auto">
                <div class="table-responsive container-lg col-12">
                    <table class="table table-striped table-hover text-center align-middle">
                        <thead class="table-dark">
                            <tr>
                                <th class="col-1">序號</th>
                                <th class="col-2">店家類型</th>
                                <th class="col-3">店家名稱</th>
                                <th class="col-2">店家啟用狀態</th>
                                <th class="col-3">排序</th>
                                <th class="col-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in top_records" :key="item">
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.store_type_name }}</td>
                                <td>
                                    {{ item.store_name }}
                                </td>
                                <td>
                                    {{ item.store_status }}
                                </td>
                                <td>
                                    <Field :name="('top_ranking' + item.store_id)" v-slot="{ meta, field }" v-model="item.top_ranking" :rules="top_ranking_rules">
                                        <input
                                            v-bind="field"
                                            class="form-control mx-auto"
                                            :class="{
                                                'is-valid': meta.valid && meta.touched,
                                                'is-invalid': !meta.valid && meta.touched,
                                            }"
                                            type="number"
                                            :min="1"
                                            :max="99999"
                                            :disabled="role_list.store_top_ranking.role.split(',')[2] !== '1'"
                                        />
                                    </Field>
                                    <ErrorMessage :name="('top_ranking' + item.store_id)" class="invalid-feedback"/>
                                </td>
                                <td>
                                    <button type="button" class="btn m-2 btn-danger" v-on:click="remove_store(item, index)" v-if="role_list.store_top_ranking.role.split(',')[2] === '1'">刪除</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 row mx-auto text-center">
                <div class="mb-3 mx-auto text-center">
                    <button type="submit" class="btn m-2 btn-primary" :disabled="search_flag === false || button_disabled === true"  v-if="role_list.store_top_ranking.role.split(',')[2] === '1'">儲存</button>
                    <button type="button" class="btn m-2 btn-danger" @click="reset()" :disabled="search_flag === false || button_disabled === true"  v-if="role_list.store_top_ranking.role.split(',')[2] === '1'">返回重置</button>
                </div>
            </div>
            <div class="mb-3">
            </div>
        </Form>

        <!-- Search Modal -->
        <div class="modal fade" id="staticBackdropSearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content" style="color: #000000;">
                    <div class="modal-header text-center bg-dark text-white">
                        <h5 class="modal-title w-100" id="staticBackdropLabel">
                            選擇店家資料
                        </h5>
                        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" v-on:click="cancel()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-12 row mb-3 mx-auto">
                            <div class="col-12 accordion mb-3" id="accordion_filter">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading_filter">
                                        <button class="accordion-button bg-dark text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_filter" aria-expanded="false" aria-controls="collapse_filter">
                                            搜尋條件
                                        </button>
                                    </h2>
                                    <div id="collapse_filter" class="accordion-collapse show" aria-labelledby="heading_filter" data-bs-parent="#accordion_filter">
                                        <div class="accordion-body col-12 row">
                                            <div class="mb-3 col-lg-4">
                                                <label for="store_code" class="form-label">店家編號</label>
                                                <input type="text" class="form-control" id="store_code" v-model="display_query_data.store_code">
                                            </div>
                                            <div class="mb-3 col-lg-4">
                                                <label for="store_name" class="form-label">店家名稱</label>
                                                <input type="text" class="form-control" id="store_name" v-model="display_query_data.store_name">
                                            </div>
                                        </div>
                                        <div class="col-12 row text-end mx-auto mb-3">
                                            <div>
                                                <button class="btn m-2 btn-primary" @click="search_store_data()">搜尋</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 row text-center mx-auto">
                                <div class="table-responsive col-12">
                                    <table class="table table-striped table-hover text-center align-middle">
                                        <thead class="table-dark">
                                            <tr>
                                                <th class="col-2">店家編號</th>
                                                <th class="col-3">店家名稱</th>
                                                <th class="col-2">店家類型</th>
                                                <th class="col-2">店家啟用狀態</th>
                                                <th class="col-1"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in records" :key="item">
                                                <td>{{ item.store_code }}</td>
                                                <td>{{ item.store_name }}</td>
                                                <td>{{ item.store_type_name }}</td>
                                                <td>{{ item.store_status }}</td>
                                                <td>
                                                    <button type="button" class="btn btn-success" v-on:click="select(item)" :disabled="this.top_records.some(data => data.store_id === item.store_id)">新增</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-12 row mx-auto mb-3">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                                            <a class="page-link" aria-label="<<"
                                                @click.prevent="onChangePage(1)">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                                            <a class="page-link" aria-label="<"
                                                @click.prevent="onChangePage(current_page - 1)">
                                                <span aria-hidden="true">&lsaquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item" v-for="page in links" :key="page" 
                                            :class="{'active': page.active, 'disabled': page.label === '...'}" v-bind:style="{'cursor': page.active ? null : page.label === '...' ? null : 'pointer'}">
                                            <a class="page-link" @click.prevent="onChangePage(page.label)">{{ page.label }}</a>
                                        </li>
                                        <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                                            <a class="page-link" aria-label=">"
                                                @click.prevent="onChangePage(current_page + 1)">
                                                <span aria-hidden="true">&rsaquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                                            <a class="page-link" aria-label=">>"
                                                @click.prevent="onChangePage(last_page)">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-12 row mx-auto mb-3">
                                <div class="input-group justify-content-center">
                                    <span class="text-vertical-center">前往第</span>
                                    <input type="number" class="form-control m-2" style="flex: 0 0 auto; width: auto;" id="current_page" v-model="current_page" :min="1" :max="9999999999">
                                    <span class="text-vertical-center">頁</span>
                                    <div>  
                                        <button class="btn m-2 btn-primary" @click.prevent="onChangePage(current_page)">前往</button>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3"></div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" v-on:click="cancel()">關閉</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    get_store_top_ranking_list,
    get_store_list_by_store_type,
    save_store_top_ranking_list,
} from '../../api/store_top_ranking';
import { mapActions, mapGetters } from 'vuex';
import { get_store_type_option } from '../../api/data';
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import Swal from 'sweetalert2';

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            drag: false,
            display: "Table",
            top_records: [],
            reset_top_records: [],
            store_type_option: [],
            remove_store_data: [],
            top_ranking_rules: yup.number().typeError("必須為數字").min(1, "最小值為1").max(99999, "最大值為99999").required("排序尚未填寫"),
            store_type: "",
            search_flag: false,
            last_page: 1,
            current_page: 1,
            links: [{
                label: "1",
                active: true,
            }],
            records: [],
            display_query_data: {
                store_code: "",
                store_name: "",
                store_type: "",
            },
            query_data: {
                store_code: "",
                store_name: "",
                store_type: "",
            },
            query_store_type: "",
            button_disabled: false,
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        remove_store(item, index) {
            this.top_records.splice(index, 1);
            if (this.reset_top_records.some(data => data.store_id === item.store_id)){
                // exists
                this.remove_store_data.push(item.store_id);
            }
        },
        search() {
            this.search_flag = false;
            this.top_records = [];
            this.reset_top_records = [];
            this.remove_store_data = [];
            this.query_store_type = JSON.parse(JSON.stringify(this.store_type));
            get_store_top_ranking_list({store_type: this.store_type}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'Index' });
                } else {
                    this.top_records = response.data.response_data;
                    this.reset_top_records = JSON.parse(JSON.stringify(response.data.response_data));
                    this.search_flag = true;
                    this.button_disabled = false;
                }
            })
        },
        reset() {
            this.top_records = JSON.parse(JSON.stringify(this.reset_top_records));
            this.remove_store_data = [];
        },
        search_store_data(page = 1, page_change = false) {
            if (page_change === false) {
                this.query_data = JSON.parse(JSON.stringify(this.display_query_data));
            }
            this.query_data.store_type = JSON.parse(JSON.stringify(this.query_store_type));
            get_store_list_by_store_type({page: page, query_data: this.query_data}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'Index' });
                } else {
                    this.records = response.data.response_data.data;
                    this.links = response.data.response_data.links;
                    this.current_page = response.data.response_data.current_page;
                    this.last_page = response.data.response_data.last_page;
                }
            })
        },
        cancel() {
            this.display_query_data = {
                store_code: "",
                store_name: "",
                store_type: "",
            }
            this.query_data = {
                store_code: "",
                store_name: "",
                store_type: "",
            }
        },
        select(item) {
            if (!this.top_records.some(data => data.store_id === item.store_id)){
                // don't exists
                const append_data = {
                    store_id: item.store_id,
                    store_name: item.store_name,
                    store_type_name: item.store_type_name,
                    store_status: item.store_status,
                    top_ranking: 99999,
                };
                this.top_records.push(append_data);
            }
        },
        save() {
            this.button_disabled = true;
            save_store_top_ranking_list({top_ranking_list: this.top_records, remove_list: this.remove_store_data}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                }
                this.reset_top_records = JSON.parse(JSON.stringify(this.top_records));
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: 'm-2 btn btn-primary',
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '提示訊息',
                    text: '更新店家置頂排序成功！',
                    icon: 'success',
                    showCloseButton: true,
                    allowOutsideClick: false,
                    confirmButtonText: '確認',
                })
            }, error => {
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            });
        },
        on_invalid_submit() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '錯誤訊息',
                text: '尚有欄位未填寫或是填寫錯誤！',
                icon: 'error',
                cancelButtonText: '關閉',
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
            });
        },
        onChangePage(page) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })
            if (page > this.last_page || page <= 0) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，無指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else if (page == this.page) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，已於指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else {
                this.search_store_data(page, true);
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        get_store_type_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.store_type_option = response.data.response_data["TW"];
            }
        })
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
    }
};
</script>
