import request from "../utils/request";

export function get_store_top_ranking_list(data: JSON){
    return request({
        url: "/getStoreTopRankingList",
        method: "post",
        data: data
    });
}

export function get_store_list_by_store_type(data: JSON){
    return request({
        url: "/getStoreListByStoreType",
        method: "post",
        data: data
    });
}

export function save_store_top_ranking_list(data: JSON){
    return request({
        url: "/saveStoreTopRankingList",
        method: "post",
        data: data
    });
}